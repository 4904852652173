/** @type {import('tailwindcss').Config} */
export default {
    content: [
        "./{pages,partials,layouts}/**/*.htm",
        "../../plugins/**/components/**/*.htm"
    ],
    safelist: [
        'after:bg-white',
        'after:bg-gray-100',
        'after:bg-green-100',
        'before:bg-white',
        'before:bg-gray-100',
        'before:bg-green-100',
    ],
    theme: {
        container: {
            center: true,
            padding: {
                DEFAULT: '2rem',
              }
        },
        extend: {
            colors: {
                'black' : '#293340',
                'slate': {
                    '50': '#f6f6f9',
                    '100': '#ecedf2',
                    '200': '#d6d7e1',
                    '300': '#b1b5c8',
                    '400': '#878ea9',
                    '500': '#686f8f',
                    '600': '#535876',
                    '700': '#444860',
                    '800': '#3b3e51',
                    '900': '#343646',
                    '950': '#21222c',
                },
                'green': {
                    'DEFAULT' : '#38b34a',
                    '50': '#f2fbf3',
                    '100': '#e0f8e3',
                    '200': '#c3efc8',
                    '300': '#94e19f',
                    '400': '#5dcb6d',
                    '500': '#38b34a',
                    '600': '#289138',
                    '700': '#23722e',
                    '800': '#205b29',
                    '900': '#1c4b25',
                    '950': '#0a2910',
                },
                'gray': {
                    'DEFAULT': '#f2f2f2',
                    '50': '#f8f8f8',
                    '100': '#f2f2f2',
                    '200': '#dcdcdc',
                    '300': '#bdbdbd',
                    '400': '#989898',
                    '500': '#7c7c7c',
                    '600': '#656565',
                    '700': '#525252',
                    '800': '#464646',
                    '900': '#3d3d3d',
                    '950': '#292929',
                },
                'orange': {
                    'DEFAULT': '#feb763',
                    '50': '#fff8ed',
                    '100': '#ffeed5',
                    '200': '#ffdaa9',
                    '300': '#feb763',
                    '400': '#fd983a',
                    '500': '#fb7a14',
                    '600': '#ec5e0a',
                    '700': '#c3460b',
                    '800': '#9b3711',
                    '900': '#7d3011',
                    '950': '#431607',
                },

            },
            fontFamily: {
                'sans': '"montserrat", sans-serif',
            },
        },
    },
    plugins: [],
}